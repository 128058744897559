import { AlertContext } from "@components/contexts/AlertContext";
import { AppContext } from "@components/contexts/AppContext";
import EmailVerificationDialog from "@components/login/EmailVerificationDialog";
import LoginPage from "@components/login/LoginPage";
import colorlog from "@libs/colorlog";
import setCloudFlow from "@libs/flows/setCloudFlow";
import { GUID_MAP, PRODUCT_MAP } from "@libs/products";
import { PAGES } from "@libs/web/urls";
import withSanitisedQuery from "@libs/withSanitisedQuery";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

const log = colorlog("pages");

interface Props {
  show: boolean;
}

const SignUp: NextPage<Props> = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const ctx = useContext(AppContext);
  const [open, setOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [pendingCredential, setPendingCredential] = useState<string>("");
  const ctxAlert = useContext(AlertContext);

  const {
    redirectapp = "",
    product = "",
    redirectpath,
    redirect,
  } = router.query;
  if (!redirectapp) {
    router.query.redirectapp = "cloud";
  }
  if (product && !redirect) {
    const guid: string = PRODUCT_MAP[product.toString()];
    router.query.redirectapp = "cloud";
    router.query.redirectpath = guid ? `/sift/${guid}` : "";
  } else {
    if (redirectpath && !redirect) {
      let productRs;
      Object.keys(GUID_MAP).forEach((e) => {
        if (redirectpath.includes(e)) {
          productRs = GUID_MAP[e];
        }
      });
      if (productRs) {
        router.query.product = productRs;
      }
    }
  }

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const auth = getAuth();
    // Sign the user out before registering for changes to state to prevent auto-login
    const unregisterAuthObserver = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        log.debug("onAuthStageChanged");

        const query = router.query;
        const {
          redirectapp,
          _ga,
          product,
          utm_source,
          utm_content,
          utm_medium,
          utm_campaign,
          utm_keyword,
        } = query;
        const tracking = {
          _ga,
          product,
          utm_source,
          utm_content,
          utm_medium,
          utm_campaign,
          utm_keyword,
        };

        const token = await user?.getIdToken();
        log.debug(`onAuthStageChanged:token`);
        if (auth.currentUser?.email) {
          setEmail(auth.currentUser?.email);
        }
        const codeHeaders: HeadersInit = new Headers();
        codeHeaders.set("authorization", "GIDP " + token!);
        log.debug("onAuthStateChanged::setting cloud flow...");
        const resp = await setCloudFlow({
          token,
          auth,
          router,
          tracking,
          ctx,
          setOpen,
        });
        if (resp?.error) {
          try {
            await fetch("/api/logout");
            await router.replace({
              pathname: PAGES.login,
              query: {
                ...router.query,
              },
            });
            ctxAlert.setAlert(resp?.message || "Error logging in", "error");
          } finally {
            queryClient.removeQueries();
            ctx.setLoading(false);
          }
        }
      } else {
        // User is signed out
        log.debug("onAuthStageChanged: signed out");
        // ...
      }
    });

    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [ctx, router, ctxAlert, queryClient]);

  if (ctx.isLoading) {
    return null;
  }

  return (
    <>
      <LoginPage isSignUp={true} setPendingCredential={setPendingCredential} />
      <EmailVerificationDialog openProps={open} email={email} />
    </>
  );
};

export default withSanitisedQuery(SignUp);
